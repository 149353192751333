import './App.css';
import Card from './components/Card';
import SETUP_IMAGE from './assets/setup.jpg';
import HeartLabel from './components/HeartLabel';
import SocialCard from './components/SocialCard';
import {
  DiscordIcon,
  InstagramIcon,
  KitIcon,
  MalLogo,
  SteamIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon,
} from './assets/Svgs';
import bg_music from './assets/music.mp3';
import { useEffect, useState } from 'react';
import { Howl, Howler } from 'howler';
import useLocalStorageState from 'use-local-storage-state';
import SoundButton from './components/SoundButton';
import Overlay from './components/Overlay';
import { twMerge } from 'tailwind-merge';

function App() {
  const sound = new Howl({
    src: [bg_music],
  });
  const [isPlaying, setIsPlaying] = useLocalStorageState(true);
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    sound.play();
    isPlaying ? Howler.volume(0.6) : Howler.volume(0);
  }, []);

  const toggleSound = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      Howler.volume(0.6);
    } else {
      Howler.volume(0);
    }
  };

  return (
    <div
      className={twMerge(
        ' min-h-screen flex items-center justify-center w-screen',
        showOverlay && 'h-screen'
      )}
    >
      {showOverlay && (
        <Overlay setIsPlaying={setIsPlaying} setShowOverlay={setShowOverlay} />
      )}
      <div className='max-w-7xl mx-auto p-4   grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-8 '>
        <div className='flex gap-4 lg:gap-8 mb-4 lg:mb-8 flex-col lg:flex-row'>
          <Card className='lg:w-1/2'>
            <h1 className='text-3xl sm:text-4xl md:text-5xl text-white font-bold '>
              Lovey
            </h1>
            <HeartLabel>About me</HeartLabel>
            <p className='text-sm sm:text-md text-text font-regular'>
              Heyy my name is Lotte I am 23 years old and live in Germany, born
              in the Netherlands. Welcome to my socials website! I've been
              gaming since 2006, starting on my GameCube, all the way till now,
              on my PC. I've built my own PC and have been doing this ever since
              2017, as it's one of my hobbys. I love gaming and do it for the
              most part of my day. Besides gaming I like to work, go shopping,
              work on my setup, sometimes do sports, or watch stuff like Anime
              or shows.
            </p>
            <div className='flex flex-wrap items-center gap-2'>
              <p className='text-text '>Games I Play:</p>
              <p className='font-medium'>FiveM,</p>
              <p className='font-medium'>CS2,</p>
              <p className='font-medium'>LoL,</p>
              <p className='font-medium'>Fortnite,</p>
              <p className='font-medium'>CoD,</p>
              <p className='font-medium'>Minecraft,</p>
              <p className='font-medium'>Valorant,</p>
              <p className='font-medium'>AQW</p>
            </div>
          </Card>
          <Card
            className='p-0 w-full aspect aspect-62/46 lg:aspect-auto'
            noPadding
          >
            <div
              className='h-full w-full'
              style={{
                background: `url(${SETUP_IMAGE})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </Card>
        </div>
        <Card className='col-span-2'>
          <HeartLabel>My Social Media</HeartLabel>
          <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8'>
            <SocialCard
              Icon={TwitterIcon}
              name='BakaLovey'
              socialPlatform='Twitter'
              url='https://twitter.com/BakaLovey'
            />
            <SocialCard
              Icon={YoutubeIcon}
              name='Lovey'
              socialPlatform='Youtube'
              url='https://www.youtube.com/channel/UC-3X27WPkOR6M9bd7qz7uPw'
            />
            <SocialCard
              Icon={DiscordIcon}
              name='Pookie'
              socialPlatform='Fortnite Tracker'
              url='https://fortnitetracker.com/profile/all/Рoоkiе'
            />
            <SocialCard
              Icon={TwitchIcon}
              name='ShyLottie'
              socialPlatform='Twitch'
              url='https://www.twitch.tv/shylottie'
            />
            <SocialCard
              Icon={KitIcon}
              name='Lovey'
              socialPlatform='Setup Kit'
              url='https://kit.co/BakaLovey'
            />
            <SocialCard
              Icon={DiscordIcon}
              name='BakaLovey'
              socialPlatform='Discord'
              url='#'
            />
            <SocialCard
              Icon={SteamIcon}
              name='Lovey'
              socialPlatform='Steam'
              url='https://steamcommunity.com/id/loveycs/'
            />
            <SocialCard
              Icon={MalLogo}
              name='Lovey'
              socialPlatform='My Anime List'
              url='https://myanimelist.net/profile/ShyLottie'
            />
          </div>
        </Card>
        <SoundButton isPlaying={isPlaying} onClick={toggleSound} />
      </div>
    </div>
  );
}

export default App;
